<template>
  <section>
      <div class="content-header">
        <h2>Research on habits</h2>
      </div>
      <div class="content-wrapper">
        <p class="pb-2">Check out these three journal articles, which discuss the power of good habits.</p>
        <h4>Co-curricular program that encourages specific study skills and habits improves academic performance and retention of first-year undergraduates in introductory biology</h4>
        <p class="pb-4"><a href="https://www.lifescied.org/doi/10.1187/cbe.20-06-0117" target="_blank">This study</a> incorporates teaching students learning strategies within an 8-week introductory Biology course to see how student success is affected. Students who participated improved time management, used more methods to plan and organize their studies and used a variety of active-learning strategies. Conclusion: Specific behavioural changes at the start of STEM gateway courses can dramatically improve student metacognition, retention, and academic performance, particularly for students underrepresented in the discipline.</p>
        <h4>First-generation college students: How to recognize them and be their ally and advocate</h4>
        <p class="pb-4"><a href="https://www.proquest.com/openview/0b0995ce04f0772baefd323e25388e4a/1?pq-origsite=gscholar&cbl=4922" target="_blank">The article</a> presents the author's views regarding teaching first-generation post-secondary students. Topics include intentionally reaching out to these students and encouraging them to develop better study habits. Educating these students about on-campus free resources; telling students about graduate scholarships and internships programs, writing letters of reference for them and teaching students about how to navigate the post-secondary system.</p>
        <h4>Examining the relationships between student learning habits and post-secondary educational outcomes: The missing component</h4>
        <p ><a href="https://www.proquest.com/openview/36c3fce6808f43f8e557beb9dcf6fb7c/1?pq-origsite=gscholar&cbl=18750" target="_blank">This study</a> analyzes nine student learning habits that social scientists have discussed as key factors for achieving positive post-secondary educational outcomes. The student’s learning habits under consideration in this study were: (a) work handed in on time, (b) less absenteeism, (c) taking harder courses, (d) diligence, (e) work habits (time spent on mathematics homework, science homework, English homework, social studies/history homework, and total homework), (f) paying attention in class, (g) participating in class, (h) doing more than what is expected, and (i) preparation for class.</p>
        <p>Of the nine student learning habits explored, the findings indicated that less absenteeism, taking harder courses, and time spent on completing mathematics homework (work habits) were the most significant predictors of students’ post-secondary educational outcomes.</p>
      </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '03',
  components: {
    // SidebarMenu,
    // Accordion
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
